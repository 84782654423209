// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#stack4{
    background-color:#e9ecef;
    height: 100vh;
    margin: 0px!important;
}`, "",{"version":3,"sources":["webpack://./src/components/Rpassword/Resetpassword.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;IACb,qBAAqB;AACzB","sourcesContent":["#stack4{\r\n    background-color:#e9ecef;\r\n    height: 100vh;\r\n    margin: 0px!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
