// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#stack2{
    background-color:#e9ecef;
    margin: 0px!important;
    height: 100vh;
   }

.forgot-password-container {
    max-width: 500px;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .forgot-password-container h1 {
    margin-top: 0;
  }
  
  .forgot-password-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .verification-method {
    margin-bottom: 10px;
  }
  
  .verification-method label {
    margin-right: 10px;
  }
  
  .forgot-password-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
  }
  
  .forgot-password-container button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ForgotUP/Forgotun.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,aAAa;GACd;;AAEH;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["#stack2{\r\n    background-color:#e9ecef;\r\n    margin: 0px!important;\r\n    height: 100vh;\r\n   }\r\n\r\n.forgot-password-container {\r\n    max-width: 500px;\r\n    margin: 10% auto;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    background-color: #f9f9f9;\r\n  }\r\n  \r\n  .forgot-password-container h1 {\r\n    margin-top: 0;\r\n  }\r\n  \r\n  .forgot-password-container input {\r\n    width: 100%;\r\n    padding: 10px;\r\n    margin-bottom: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 3px;\r\n  }\r\n  \r\n  .verification-method {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .verification-method label {\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  .forgot-password-container button {\r\n    width: 100%;\r\n    padding: 10px;\r\n    background-color: #007bff;\r\n    border: none;\r\n    border-radius: 3px;\r\n    color: #fff;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .forgot-password-container button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
