import React from "react";
import Index from "./Index";
import Header from '../../Header';
import Footer from '../../Footer';




const Home = () =>{
    return(
        <>
        <Header/>
        <Index/>
        <Footer/>
       
        
        
        
        </>
    )
}
export default Home;