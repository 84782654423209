// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.register-success-container {
    max-width: 750px;
    margin: 100px auto;
    padding: 75px;
    background-color: rgba(0, 0, 0, .70);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .register-success-container p{
    color: white;
  }
  
  .register-success-container h2 {
    color: white
  } 
  
  /* p {
    margin-top: 20px;
  } */
  
  /* Additional styling for mobile responsiveness */
  @media (max-width: 600px) {
    .register-success-container {
      max-width: 300px;
      padding: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Mwelcomeverify/Mwelcomeverify.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;IAClB,uCAAuC;EACzC;EACA;IACE,YAAY;EACd;;EAEA;IACE;EACF;;EAEA;;KAEG;;EAEH,iDAAiD;EACjD;IACE;MACE,gBAAgB;MAChB,aAAa;IACf;EACF","sourcesContent":["\r\n\r\n.register-success-container {\r\n    max-width: 750px;\r\n    margin: 100px auto;\r\n    padding: 75px;\r\n    background-color: rgba(0, 0, 0, .70);\r\n    border-radius: 5px;\r\n    text-align: center;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  }\r\n  .register-success-container p{\r\n    color: white;\r\n  }\r\n  \r\n  .register-success-container h2 {\r\n    color: white\r\n  } \r\n  \r\n  /* p {\r\n    margin-top: 20px;\r\n  } */\r\n  \r\n  /* Additional styling for mobile responsiveness */\r\n  @media (max-width: 600px) {\r\n    .register-success-container {\r\n      max-width: 300px;\r\n      padding: 10px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
